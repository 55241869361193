import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vila-Solta/';

const Project40 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>40</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Vila Šolta</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Stambena namjena</p>
            <p>Lokacija: Šolta</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}A_day1.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}A_day2.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}A_day3.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneL">
          <img src={`${URL}A_day4.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}A_day5.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}A_day6.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}A_day7.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneR">
          <img src={`${URL}A_day8.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}A_day9.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}A_day10.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}A_night1.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneL">
          <img src={`${URL}A_night1_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}A_night3.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}A_night3a.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}A_night3_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneR">
          <img src={`${URL}A_night4.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}A_night5.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}A_night6.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_day1.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneL">
          <img src={`${URL}B_day1_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}B_day2.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_day2_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_day3.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneR">
          <img src={`${URL}B_day4.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_day5.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}B_day6.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_day7.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneL">
          <img src={`${URL}B_day8.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}B_day8_b.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_day9.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_night1.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneR">
          <img src={`${URL}B_night1_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_night2.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}B_night2_b.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_night3.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneL">
          <img src={`${URL}B_night4.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}B_night5.jpeg`} alt="Vila Šolta" />
          <img src={`${URL}B_night6.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_night7.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="oneR">
          <img src={`${URL}B_night7_b.jpeg`} alt="Vila Šolta" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}B_night8.jpeg`} alt="Vila Šolta" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project40;
