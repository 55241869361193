import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-B-Pregrada/';

const Project28 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>28</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Kuća B Pregrada</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Stambena namjena</p>
            <p>Lokacija: Pregrada</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}1.jpeg`} alt="Kuća B Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}2.jpeg`} alt="Kuća B Pregrada" />
          <img src={`${URL}3.jpeg`} alt="Kuća B Pregrada" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project28;
