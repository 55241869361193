import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Djecje-Igraliste-Jesenje/';

const Project7 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>07</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Dječje igralište Jesenje</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Javne zgrade</p>
            <p>Lokacija: Jesenje</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}1.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}2.jpeg`} alt="Dječje igralište Jesenje" />
          <img src={`${URL}3.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="oneL">
          <img src={`${URL}a.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}a1.jpeg`} alt="Dječje igralište Jesenje" />
          <img src={`${URL}a2.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}a3.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="oneR">
          <img src={`${URL}a4.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}a5.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}a6.jpeg`} alt="Dječje igralište Jesenje" />
          <img src={`${URL}a7.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
        <div className="oneL">
          <img src={`${URL}a8.jpeg`} alt="Dječje igralište Jesenje" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project7;
