import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-KP-B-Pregrada/';

const Project47 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>47</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Zgrada KP-B Pregrada</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Stambena namjena</p>
            <p>Lokacija: Pregrada</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}1.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}1c.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}4.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}5.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}6.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}8.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}9.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneR">
          <img src={`${URL}10.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}11.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}12.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}13.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}14.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}b.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}c.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}d.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneR">
          <img src={`${URL}e.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}f.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}g.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}h.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}i.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}j.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}k.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}prizemlje.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="oneR">
          <img src={`${URL}kat.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}podloga.jpeg`} alt="Zgrada KP-B Pregrada" />
          <img src={`${URL}situacija.jpeg`} alt="Zgrada KP-B Pregrada" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project47;
