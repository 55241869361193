import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Dacki-dom-Pregrada/DACKI_DOM_';

const Project6 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>06</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Đački dom Pregrada</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Javne zgrade</p>
            <p>Lokacija: Pregrada</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}1.jpeg`} alt="Đački dom Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}2.jpeg`} alt="Đački dom Pregrada" />
          <img src={`${URL}3.jpeg`} alt="Đački dom Pregrada" />
        </div>
       
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project6;
