import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import logo from '../../assets/kostelgrad_transparent.webp';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  const onScroll = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener('scroll', onScroll);

  return (
    <nav
      style={{
        backgroundColor: scroll ? 'white' : 'transparent',
      }}
    >
      <div className="navbar">
        <div
          className="menuButton"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <div className="menuButton">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
        <NavLink to="/" className="logo">
          <img src={logo} alt="Kostelgrad logo" />
        </NavLink>
      </div>
      <div
        className="sideMenu"
        style={{
          left: openMenu ? '0' : '-20rem',
          transitionDelay: openMenu ? '0s' : '0.2s',
        }}
      >
        <div
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <div className="sideMenu-top">
            <div className="menuButton">
              <div className="line" style={{ backgroundColor: '#fff' }}></div>
              <div className="line" style={{ backgroundColor: '#fff' }}></div>
              <div className="line" style={{ backgroundColor: '#fff' }}></div>
            </div>
          </div>
          <div className="sideMenu-links">
            <div className="sideMenu-navLinks">
              <ul>
                <li className="navLink">
                  <NavLink
                    to="/"
                    onClick={() => setOpenMenu(!openMenu).window.scrollTo(0, 0)}
                    style={{
                      top: openMenu ? '0' : '100px',
                      transitionDelay: openMenu ? '0.7s' : '0s',
                    }}
                  >
                    Početna
                  </NavLink>
                  <div className="navLink-wrapper"></div>
                </li>
                <li className="navLink">
                  <NavLink
                    to="projekti"
                    onClick={() => setOpenMenu(!openMenu).window.scrollTo(0, 0)}
                    style={{
                      top: openMenu ? '0' : '100px',
                      transitionDelay: openMenu ? '0.8s' : '0s',
                    }}
                  >
                    Projekti
                  </NavLink>
                  <div className="navLink-wrapper"></div>
                </li>
                <li className="navLink">
                  <NavLink
                    to="o-nama"
                    onClick={() => setOpenMenu(!openMenu).window.scrollTo(0, 0)}
                    style={{
                      top: openMenu ? '0' : '100px',
                      transitionDelay: openMenu ? '0.9s' : '0s',
                    }}
                  >
                    Usluge
                  </NavLink>
                  <div className="navLink-wrapper"></div>
                </li>
                <li className="navLink">
                  <NavLink
                    to="kontakt"
                    onClick={() => setOpenMenu(!openMenu).window.scrollTo(0, 0)}
                    style={{
                      top: openMenu ? '0' : '100px',
                      transitionDelay: openMenu ? '1s' : '0s',
                    }}
                  >
                    Kontakt
                  </NavLink>
                  <div className="navLink-wrapper"></div>
                </li>
              </ul>
            </div>
            <div className="sideMenu-socials">
              {/* <p>fb</p>
              <p>in</p>
              <p>li</p> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
