const projects = [
  {
    id: 16,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Poslovna-zgrada-KoPro-Pregrada/Pregrada_poslovna.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Poslovna zgrada KoPro Pregrada',
    category: 'Poslovne zgrade',
    slug: 'poslovna-zgrada-kopro',
  },
  {
    id: 17,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-AS-Pregrada/HALA_AS.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada AS Pregrada',
    category: 'Poslovne zgrade',
    slug: 'zgrada-as-pregrada',
  },
  {
    id: 21,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-MI-Krapina/HALA_MI_1.jpeg',
    location: 'Krapina, Hrvatska',
    title: 'Zgrada MI Krapina',
    category: 'Poslovne zgrade',
    slug: 'zgrada-mi-krapina',
  },
  {
    id: 9,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Djecji-vrtic-Pregrada-Vrhi/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Dječji vrtić Pregrada Vrhi',
    category: 'Javne zgrade',
    slug: 'djecji-vrtic-pregrada-vrhi',
  },
  {
    id: 28,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-B-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća B Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-b-pregrada',
  },
  {
    id: 29,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-BC-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća BC Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-bc-pregrada',
  },
  {
    id: 30,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-Cc-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća Cc Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-cc-pregrada',
  },
  {
    id: 31,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-JG-Ivanic-Grad/0.jpeg',
    location: 'Ivanić Grad, Hrvatska',
    title: 'Kuća JG Ivanić Grad',
    category: 'Stambena namjena',
    slug: 'kuca-jg-ivanic-grad',
  },
  {
    id: 32,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-K-Zabok-2/a.jpeg',
    location: 'Zabok, Hrvatska',
    title: 'Kuća K Zabok',
    category: 'Stambena namjena',
    slug: 'kuca-k-zabok-2',
  },
  {
    id: 33,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-KC-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća KC Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-kc-pregrada',
  },
  {
    id: 35,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-M-Tuhelj/0.jpeg',
    location: 'Tuhelj, Hrvatska',
    title: 'Kuća M Tuhelj',
    category: 'Stambena namjena',
    slug: 'kuca-m-tuhelj',
  },
  {
    id: 36,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-P-Durmanec/1.jpeg',
    location: 'Đurmanec, Hrvatska',
    title: 'Kuća P Đurmanec',
    category: 'Stambena namjena',
    slug: 'kuca-p-durmanec',
  },
  {
    id: 37,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-S-Pregrada/Page_3.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća Š Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-s-pregrada',
  },
  {
    id: 38,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-za-odmor-N-Zman/1a.jpeg',
    location: 'Žman, Hrvatska',
    title: 'Kuća za odmor N Žman',
    category: 'Stambena namjena',
    slug: 'kuca-za-odmor-n-zman',
  },
  {
    id: 39,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuce-u-nizu-G-Pregrada/nizu3.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuće u nizu G Pregrada',
    category: 'Stambena namjena',
    slug: 'kuce-u-nizu-g-pregrada',
  },
  {
    id: 40,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vila-Solta/A_day1.jpeg',
    location: 'Šolta, Hrvatska',
    title: 'Vila Šolta',
    category: 'Stambena namjena',
    slug: 'vila-solta',
  },
  {
    id: 41,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vila-1-Kukljica/(4).jpeg',
    location: 'Kukljica, Hrvatska',
    title: 'Vila 1 Kukljica',
    category: 'Stambena namjena',
    slug: 'vila-1-kukljica',
  },
  {
    id: 42,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vila-2-Kukljica/6.jpeg',
    location: 'Kukljica, Hrvatska',
    title: 'Vila 2 Kukljica',
    category: 'Stambena namjena',
    slug: 'vila-2-kukljica',
  },
  {
    id: 5,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/BioloskiBazenPregrada/BioloskiBazenPregrada(3).jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Biološki bazen Pregrada',
    category: 'Javne zgrade',
    slug: 'bioloski-bazen-pregrada',
  },
  {
    id: 6,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Dacki-dom-Pregrada/DACKI_DOM_3.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Đački dom Pregrada',
    category: 'Javne zgrade',
    slug: 'dacki-dom-pregrada',
  },
  {
    id: 7,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Djecje-Igraliste-Jesenje/1.jpeg',
    location: 'Jesenje, Hrvatska',
    title: 'Dječje igralište Jesenje',
    category: 'Javne zgrade',
    slug: 'djecje-igraliste-jesenje',
  },
  {
    id: 8,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Djecje-Igraliste-Krapinske-Toplice/c2.jpeg',
    location: 'Krapinske Toplice, Hrvatska',
    title: 'Dječje igralište Krapinske Toplice',
    category: 'Javne zgrade',
    slug: 'djecje-igraliste-krapinske-toplice',
  },
 
  {
    id: 10,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Trg-Tuhelj/1.jpeg',
    location: 'Tuhelj, Hrvatska',
    title: 'Trg Tuhelj',
    category: 'Javne zgrade',
    slug: 'trg-tuhelj',
  },
  {
    id: 11,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Fontana-Tuheljske-Toplice/5.jpeg',
    location: 'Tuheljske Toplice, Hrvatska',
    title: 'Fontana Tuheljske Toplice',
    category: 'Krajobrazna arhitektura',
    slug: 'fontana-tuheljske-toplice',
  },
  {
    id: 12,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Ljekarnicki-vrt/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Ljekrnički vrt Pregrada',
    category: 'Krajobrazna arhitektura',
    slug: 'ljekarnicki-vrt-pregrada',
  },
  {
    id: 13,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Mirkovec/1.jpeg',
    location: 'Hrvatska',
    title: 'Mirkovec',
    category: 'Krajobrazna arhitektura',
    slug: 'mirkovec',
  },
  {
    id: 14,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vrt-E/3.jpeg',
    location: 'Hrvatska',
    title: 'Vrt E',
    category: 'Krajobrazna arhitektura',
    slug: 'vrt-e',
  },
  {
    id: 15,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Automehanika-i-Vulkanizerstvo-M/automehanika.jpeg',
    location: 'Hrvatska',
    title: 'Automehanika i vulkanizerstvo M',
    category: 'Poslovne zgrade',
    slug: 'automehanika-i-vulkanizerstvo-m',
  },
  
 
  {
    id: 18,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-BA-Pregrada/HALA_BA_2.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada BA Pregrada',
    category: 'Poslovne zgrade',
    slug: 'zgrada-ba-pregrada',
  },
  // {
  //   id: 19,
  //   image:
  //     'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-Br-Hum-na-Sutli/HALA_AS.jpeg',
  //   location: 'Hum na Sutli, Hrvatska',
  //   title: 'Zgrada Br Hum na Sutli',
  //   category: 'Poslovne zgrade',
  //   slug: 'zgrada-br-hum-na-sutli',
  // },
  {
    id: 20,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-KP-A/00.jpeg',
    location: 'Hrvatska',
    title: 'Zgrada KP A',
    category: 'Poslovne zgrade',
    slug: 'zgrada-kp-a',
  },
 
  {
    id: 22,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-SB-Pregrada/HALA_SB.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada SB Pregrada',
    category: 'Poslovne zgrade',
    slug: 'zgrada-sb-pregrada',
  },
  {
    id: 23,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-V-Krapina/HALA_V_1.jpeg',
    location: 'Krapina, Hrvatska',
    title: 'Zgrada V Krapina',
    category: 'Poslovne zgrade',
    slug: 'zgrada-v-krapina',
  },
  {
    id: 24,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-J-Pregrada/Prizemlje.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada J Pregrada',
    category: 'Zgrade mješovite namjene',
    slug: 'zgrada-j-pregrada',
  },
  {
    id: 25,
    image: '',
    location: 'Zaprešić, Hrvatska',
    title: 'Zgrada J Zaprešić',
    category: 'Zgrade mješovite namjene',
    slug: 'zgrada-j-zapresic',
  },
  {
    id: 26,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-K-Zabok/STAMBENO-POSLOVNA_ZGRADA_K.jpeg',
    location: 'Zabok, Hrvatska',
    title: 'Zgrada K Zabok',
    category: 'Zgrade mješovite namjene',
    slug: 'zgrada-k-zabok',
  },
  {
    id: 27,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-KP-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada KP Pregrada',
    category: 'Zgrade mješovite namjene',
    slug: 'zgrada-kp-pregrada',
  },
  
  {
    id: 34,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-L-Pregrada/0.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća L Pregrada',
    category: 'Stambena namjena',
    slug: 'kuca-l-pregrada',
  },
  
  {
    id: 43,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-C-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada C Pregrada',
    category: 'Stambena namjena',
    slug: 'zgrada-c-pregrada',
  },
  {
    id: 44,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-G-Krapinske-Toplice/1.jpeg',
    location: 'Krapinske Toplice, Hrvatska',
    title: 'Zgrada G Krapinske Toplice',
    category: 'Stambena namjena',
    slug: 'zgrada-g-krapinske-toplice',
  },
  {
    id: 45,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-K-Pag/A2.jpeg',
    location: 'Pag, Hrvatska',
    title: 'Zgrada K Pag',
    category: 'Stambena namjena',
    slug: 'zgrada-k-pag',
  },
  {
    id: 46,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-K-Zlatar/nizu3.jpeg',
    location: 'Zlatar, Hrvatska',
    title: 'Zgrada K Zlatar',
    category: 'Stambena namjena',
    slug: 'zgrada-k-zlatar',
  },
  {
    id: 47,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-KP-B-Pregrada/8.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada KP-B Pregrada',
    category: 'Stambena namjena',
    slug: 'zgrada-kp-b-pregrada',
  },
  {
    id: 48,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Zgrada-KP-E-Pregrada/1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Zgrada KP-E Pregrada',
    category: 'Stambena namjena',
    slug: 'zgrada-kp-e-pregrada',
  },
  {
    id: 1,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-K-Zabok/0.jpeg',
    location: 'Zabok, Hrvatska',
    title: 'Kuća K Zabok',
    category: 'Interijeri',
    slug: 'kuca-k-zabok',
  },
  {
    id: 2,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-KP-B-Pregrada/8.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća KP B Pregrada',
    category: 'Interijeri',
    slug: 'kuca-kp-b-pregrada',
  },
  {
    id: 3,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-KP-C-Pregrada/e1.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća KP C Pregrada',
    category: 'Interijeri',
    slug: 'kuca-kp-c-pregrada',
  },
  {
    id: 4,
    image:
      'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-P-Pregrada/5.jpeg',
    location: 'Pregrada, Hrvatska',
    title: 'Kuća P Pregrada',
    category: 'Interijeri',
    slug: 'kuca-p-pregrada',
  },
];

export default projects;
