import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/BioloskiBazenPregrada/BioloskiBazenPregrada';

const Project5 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>05</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Biološki bazen Pregrada</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Javne zgrade</p>
            <p>Lokacija: Pregrada</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}(1).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}(2).jpeg`} alt="Biološki bazen Pregrada" />
          <img src={`${URL}(3).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}(4).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}(5).jpeg`} alt="Biološki bazen Pregrada" />
          <img src={`${URL}(6).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}(7).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="oneR">
          <img src={`${URL}(8).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}(9).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}(10).jpeg`} alt="Biološki bazen Pregrada" />
          <img src={`${URL}(11).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}(12).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}(13).jpeg`} alt="Biološki bazen Pregrada" />
          <img src={`${URL}(14).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}(15).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="oneR">
          <img src={`${URL}(16).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}(17).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
        <div className="oneL">
          <img src={`${URL}(18).jpeg`} alt="Biološki bazen Pregrada" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project5;
