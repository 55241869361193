import { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

import projects from '../../data/data';

const categories = [
  {
    title: 'Stambena namjena',
    number: 21,
  },
  {
    title: 'Zgrade mješovite namjene',
    number: 4,
  },
  {
    title: 'Poslovne zgrade',
    number: 8,
  },
  {
    title: 'Javne zgrade',
    number: 6,
  },

  {
    title: 'Krajobrazna arhitektura',
    number: 4,
  },
  {
    title: 'Interijeri',
    number: 4,
  },
];

const WorkPage = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);

  const filteredProjects = projects.filter((p) => {
    if (filter === null) {
      return projects;
    } else {
      return p.category === filter;
    }
  });

  return (
    <div className="workPage">
      <div className="workPageHeader">
        <h1>Projekti</h1>
        <div className="workPageFilters">
          <h3
            onClick={() => {
              setOpenFilter(!openFilter);
            }}
          >
            {openFilter ? 'Kategorije' : 'Filter'}
          </h3>
          {categories.map((c) => (
            <div
              className="category"
              style={{ display: openFilter ? 'flex' : '' }}
              key={c.title}
              onClick={() => {
                setFilter(c.title);
                setOpenFilter(!openFilter);
              }}
            >
              <p>{c.title}</p>
              <span>{c.number}</span>
            </div>
          ))}
          <div
            className="category"
            onClick={() => {
              setFilter(null);
              setOpenFilter(!openFilter);
            }}
            style={{ display: openFilter ? 'flex' : '' }}
          >
            <p>Svi projekti</p>
            <p>{projects.length}</p>
          </div>
        </div>
      </div>
      <div className="workGrid">
        {filteredProjects.map((p) => (
          <Link to={p.slug} className="gridItem" key={p.id}>
            <img src={p.image} alt={p.title} />
            <div className="itemTitle">
              <p>{p.location}</p>
              <p>{p.title}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default WorkPage;
