import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-za-odmor-N-Zman/';

const Project38 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>38</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Kuća za odmor N Žman</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Stambena namjena</p>
            <p>Lokacija: Žman</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}1a.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}2a.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}3a.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="oneL">
          <img src={`${URL}4a.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}5.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}6.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}7.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="oneR">
          <img src={`${URL}8.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}8a.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}9.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}10.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="oneL">
          <img src={`${URL}11b.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}12.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}13.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}14.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="oneR">
          <img src={`${URL}15a.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}15b.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}15c.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}16.jpeg`} alt="Kuća za odmor N Žman" />
        </div>

        <div className="twolLrS">
          <img src={`${URL}NACRT1.jpeg`} alt="Kuća za odmor N Žman" />
          <img src={`${URL}NACRT2.jpeg`} alt="Kuća za odmor N Žman" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project38;
