import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { Link, Outlet } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <CookieConsent
        buttonText="Prihvati"
        style={{
          background: 'white',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          color: 'black',
        }}
        buttonStyle={{
          color: 'white',
          backgroundColor: '#A4D1CE',
          fontSize: '1rem',
          textTransform: 'uppercase',
          borderRadius: '5px',
          padding: '0.5rem 1rem',
        }}
        expires={150}
      >
        Ova web stranica koristi kolačiće kako bi se osiguralo bolje korisničko
        iskustvo. Više informacija pronađite{' '}
        <Link to="/politika-privatnosti">ovdje</Link>.{' '}
      </CookieConsent>
      <Footer />
    </>
  );
};

export default MainLayout;
