import React from 'react';
import { useRoutes } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import ContactPage from '../pages/ContactPage/ContactPage';
import MainLayout from '../layouts/MainLayout';
import WorkPage from '../pages/WorkPage/WorkPage';
import AboutUs from '../pages/AboutUs/AboutUs';
import Project1 from '../pages/Projects/Project1';
import Project2 from '../pages/Projects/Project2';
import Project3 from '../pages/Projects/Project3';
import Project4 from '../pages/Projects/Project4';
import Project5 from '../pages/Projects/Project5';
import Project6 from '../pages/Projects/Project6';
import Project7 from '../pages/Projects/Project7';
import Project8 from '../pages/Projects/Project8';
import Project9 from '../pages/Projects/Project9';
import Project10 from '../pages/Projects/Project10';
import Project11 from '../pages/Projects/Project11';
import Project12 from '../pages/Projects/Project12';
import Project13 from '../pages/Projects/Project13';
import Project14 from '../pages/Projects/Project14';
import Project15 from '../pages/Projects/Project15';
import Project16 from '../pages/Projects/Project16';
import Project17 from '../pages/Projects/Project17';
import Project18 from '../pages/Projects/Project18';
import Project19 from '../pages/Projects/Project19';
import Project20 from '../pages/Projects/Project20';
import Project21 from '../pages/Projects/Project21';
import Project22 from '../pages/Projects/Project22';
import Project23 from '../pages/Projects/Project23';
import Project24 from '../pages/Projects/Project24';
import Project25 from '../pages/Projects/Project25';
import Project26 from '../pages/Projects/Project26';
import Project27 from '../pages/Projects/Project27';
import Project28 from '../pages/Projects/Project28';
import Project29 from '../pages/Projects/Project29';
import Project30 from '../pages/Projects/Project30';
import Project31 from '../pages/Projects/Project31';
import Project32 from '../pages/Projects/Project32';
import Project33 from '../pages/Projects/Project33';
import Project34 from '../pages/Projects/Project34';
import Project35 from '../pages/Projects/Project35';
import Project36 from '../pages/Projects/Project36';
import Project37 from '../pages/Projects/Project37';
import Project38 from '../pages/Projects/Project38';
import Project39 from '../pages/Projects/Project39';
import Project40 from '../pages/Projects/Project40';
import Project41 from '../pages/Projects/Project41';
import Project42 from '../pages/Projects/Project42';
import Project43 from '../pages/Projects/Project43';
import Project44 from '../pages/Projects/Project44';
import Project45 from '../pages/Projects/Project45';
import Project46 from '../pages/Projects/Project46';
import Project47 from '../pages/Projects/Project47';
import Project48 from '../pages/Projects/Project48';
import PrivacyPage from '../pages/PrivacyPage/PrivacyPage';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: 'o-nama', element: <AboutUs /> },
        { path: 'kontakt', element: <ContactPage /> },
        { path: 'politika-privatnosti', element: <PrivacyPage /> },
        {
          path: 'projekti',

          children: [
            {
              index: true,
              element: <WorkPage />,
            },
            { path: 'kuca-k-zabok', element: <Project1 /> },
            { path: 'kuca-kp-b-pregrada', element: <Project2 /> },
            { path: 'kuca-kp-c-pregrada', element: <Project3 /> },
            { path: 'kuca-p-pregrada', element: <Project4 /> },
            { path: 'bioloski-bazen-pregrada', element: <Project5 /> },
            { path: 'dacki-dom-pregrada', element: <Project6 /> },
            { path: 'djecje-igraliste-jesenje', element: <Project7 /> },
            { path: 'djecje-igraliste-krapinske-toplice', element: <Project8 /> },
            { path: 'djecji-vrtic-pregrada-vrhi', element: <Project9 /> },
            { path: 'trg-tuhelj', element: <Project10 /> },
            { path: 'fontana-tuheljske-toplice', element: <Project11 /> },
            { path: 'ljekarnicki-vrt-pregrada', element: <Project12 /> },
            { path: 'mirkovec', element: <Project13 /> },
            { path: 'vrt-e', element: <Project14 /> },
            { path: 'automehanika-i-vulkanizerstvo-m', element: <Project15 /> },
            { path: 'poslovna-zgrada-kopro', element: <Project16 /> },
            { path: 'zgrada-as-pregrada', element: <Project17 /> },
            { path: 'zgrada-ba-pregrada', element: <Project18 /> },
            { path: 'zgrada-br-hum-na-sutli', element: <Project19 /> },
            { path: 'zgrada-kp-a', element: <Project20 /> },
            { path: 'zgrada-mi-krapina', element: <Project21 /> },
            { path: 'zgrada-sb-pregrada', element: <Project22 /> },
            { path: 'zgrada-v-krapina', element: <Project23 /> },
            { path: 'zgrada-j-pregrada', element: <Project24 /> },
            { path: 'zgrada-j-zapresic', element: <Project25 /> },
            { path: 'zgrada-k-zabok', element: <Project26 /> },
            { path: 'zgrada-kp-pregrada', element: <Project27 /> },
            { path: 'kuca-b-pregrada', element: <Project28 /> },
            { path: 'kuca-bc-pregrada', element: <Project29 /> },
            { path: 'kuca-cc-pregrada', element: <Project30 /> },
            { path: 'kuca-jg-ivanic-grad', element: <Project31 /> },
            { path: 'kuca-k-zabok-2', element: <Project32 /> },
            { path: 'kuca-kc-pregrada', element: <Project33 /> },
            { path: 'kuca-l-pregrada', element: <Project34 /> },
            { path: 'kuca-m-tuhelj', element: <Project35 /> },
            { path: 'kuca-p-durmanec', element: <Project36 /> },
            { path: 'kuca-s-pregrada', element: <Project37 /> },
            { path: 'kuca-za-odmor-n-zman', element: <Project38 /> },
            { path: 'kuce-u-nizu-g-pregrada', element: <Project39 /> },
            { path: 'vila-solta', element: <Project40 /> },
            { path: 'vila-1-kukljica', element: <Project41 /> },
            { path: 'vila-2-kukljica', element: <Project42 /> },
            { path: 'zgrada-c-pregrada', element: <Project43 /> },
            { path: 'zgrada-g-krapinske-toplice', element: <Project44 /> },
            { path: 'zgrada-k-pag', element: <Project45 /> },
            { path: 'zgrada-k-zlatar', element: <Project46 /> },
            { path: 'zgrada-kp-b-pregrada', element: <Project47 /> },
            { path: 'zgrada-kp-e-pregrada', element: <Project48 /> },
          ],
        },
      ],
    },
  ]);
}
