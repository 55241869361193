import './style.scss';

import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const ContactPage = () => {
  return (
    <div className="contactPage">
      {/* <header>
        <Image src="/header1.webp" height={400} width={1200} alt="Project" />
      </header> */}
      {/* <div className="contactContainer"> */}
      <div className="contactForm">
        {/* <h3>Kontaktirajte nas</h3> */}
        <form>
          <div className="input">
            <p>Ime i prezime</p>
            <input type="text" required />
          </div>
          <div className="input">
            <p>Telefon</p>
            <input type="phone" />
          </div>
          <div className="input">
            <p>Email</p>
            <input type="email" required />
          </div>
          <div className="input">
            <p>Poruka</p>
            <textarea name="Poruka" id="" rows="3" required />
          </div>
          <button>Pošalji</button>
        </form>
      </div>
      <div className="contactInfo">
        <h3>
          {/* <PhoneOutlinedIcon /> */}
          Nazovite nas
        </h3>
        <div className="contactInfo-row">
          <a href="tel:+38549376323">+385 49 376 323</a>
          <br />
          <a href="tel:+38549300686">+385 49 300 686</a>
        </div>
        <h3>
          {/* <EmailOutlinedIcon /> */}
          Pišite nam
        </h3>
        <div className="contactInfo-row">
          <a href="mailto:info@kostelgrad-projekt.hr">
            info@kostelgrad-projekt.hr
          </a>
        </div>
        <h3>
          {/* <AccessTimeOutlinedIcon /> */}
          Radno vrijeme
        </h3>
        <div className="contactInfo-row">
          <p>Ponedjeljak - Petak</p>
          <p>07:00 - 15:00</p>
        </div>
        <h3>
          {/* <LocationOnOutlinedIcon /> */}
          Adresa
        </h3>
        <div className="contactInfo-row">
          <p>Obrtnička ulica 5 | 49218 Pregrada</p>
          <p></p>
          {/* <p>Hrvatska</p> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ContactPage;
