import { Link } from 'react-router-dom';
import './style.scss';


import projektiranje from '../../assets/projektiranje.webp'
import savjetovanje from '../../assets/savjetovanje.webp'
import troskovnik from '../../assets/troskovnik.webp'
import nadzor from '../../assets/nadzor.webp'
import Carousel from '../../components/Carousel/Carousel';

const HomePage = () => {
 

  return (
    <div className="homePage">
      
      <Carousel/>

      <div className="homePageIntro">
        <h2>Kreiramo prostor s karakterom.</h2>
        <div className="introText">
          <p>
          Naš arhitektonski ured donosi godine iskustva i stručnosti u svaki projekt. Sa svojim timom od 8 vrhunskih stručnjaka u području arhitekture, građevinarstva i krajobrazne arhitekture, pružamo sveobuhvatne projektantske usluge. Naša svrha je ostvariti vaše zamisli i stvoriti izvanredne prostore i građevinska rješenja koja će vas zadovoljiti. Dobrodošli u svijet kreativnog dizajna i izvrsne izvedbe.
          </p>
          <Link to="o-nama">Saznaj više</Link>
        </div>
      </div>

      <div className="homePageWork">
        <div className="textContainer">
          <h3>
            Biološki bazen Pregrada
          </h3>
          <p>
            {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel facere
            eum tempora saepe unde doloribus vero, animi id itaque, earum
            perferendis cum commodi dolorum cupiditate quae eos quos ipsa
            voluptatum. Praesentium eius sequi commodi nobis at tempora repellat
            aspernatur facere! */}
          </p>
          <Link to="projekti/bioloski-bazen-pregrada">Saznaj više</Link>
        </div>
        <div className="image">
          <img
            src="https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/BioloskiBazenPregrada/BioloskiBazenPregrada(4).jpeg"
            alt=""
          />
        </div>
      </div>

      <div className="homePageServices">
        <h1>Naše usluge</h1>
        <div className="serviceContainer">
          <div className="image">
            <img
              src={projektiranje}
              alt="Projektiranje"
            />
          </div>
          <div className="text" style={{ paddingLeft: '5%' }}>
            <h3>Projektiranje</h3>
            <p>
              Izrada idejnih rješenja, idejnog projekta, glavnog projekta,
              izvedbenog projekta, ishođenje dozvola za građenje.
            </p>
          </div>
        </div>
        <div className="serviceContainer">
          <div className="text" style={{ paddingRight: '5%' }}>
            <h3>Izrada troškovnika i procjena vrijednosti nekretnine</h3>
            <p>
              Izrađujemo troškovnike za ishođenje kredita, procjene vrijednosti
              zgrada i nekretnina – ovlašteni sudski vještak
            </p>
          </div>
          <div className="image">
            <img
              src={troskovnik}
              alt="Troskovnik"
            />
          </div>
        </div>
        <div className="serviceContainer">
          <div className="image">
            <img
              src={nadzor}
              alt="Nadzor"
            />
          </div>
          <div className="text" style={{ paddingLeft: '5%' }}>
            <h3>Nadzor</h3>
            <p>Vršimo projektantski i stručni nadzor prilikom gradnje.</p>
          </div>
        </div>
        <div className="serviceContainer">
          <div className="text" style={{ paddingRight: '5%' }}>
            <h3>Savjetovanje</h3>
            <p>
              Savjetujemo investitore u svim fazama projektiranja i gradnje.
            </p>
          </div>
          <div className="image">
            <img
              src={savjetovanje}
              alt="Savjetovanje"
            />
          </div>
        </div>
      </div>

      <div className="homePageWork">
        <div className="image">
          <img
            src="https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vila-Solta/A_day2.jpeg"
            alt="Vila Solta"
          />
        </div>
        <div className="textContainer">
          <h3>Pogledajte neke naše projekte</h3>
          <p>
          Svaki projekt je priča, a naši projekti su priče o transformaciji ideja u stvarnost. Pregledajte neke od naših najrecentnijih uspješnih projekata kako biste dobili uvid u našu kreativnost, stručnost i posvećenost izgradnji izvanrednih prostora. Naš portfelj govori više od riječi, on priča priču o našem radu.
          </p>
          <Link to="projekti">Saznaj više</Link>
        </div>
      </div>

      <div className="homePageContact">
        <h2>Imate projekt za nas?</h2>
        <Link to="kontakt">Kontaktirajte nas</Link>
      </div>
    </div>
  );
};

export default HomePage;
