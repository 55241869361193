import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// FONTS
import './fonts/RecklessNeue-Book.ttf'
import './fonts/NeueHaasDisplayXXThin.ttf'
import './fonts/NeueHaasDisplayXThin.ttf'
import './fonts/NeueHaasDisplayThin.ttf'
import './fonts/NeueHaasDisplayLight.ttf'
import './fonts/NeueHaasDisplayRoman.ttf'
import './fonts/NeueHaasDisplayMediu.ttf'
import './fonts/NeueHaasDisplayBold.ttf'
import './fonts/NeueHaasDisplayBlack.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


