import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL = 'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuca-K-Zabok/'

const Project1 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>01</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Kuća K</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Dizajn interijera</p>
            <p>Lokacija: Zabok</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img
            src={`${URL}0.jpeg`}
            alt="Kuća K Zabok"
          />
        </div>
        <div className="twolSrL">
          <img src={`${URL}1.jpeg`} alt="Kuća K Zabok" />
          <img src={`${URL}2.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="oneL">
          <img src={`${URL}3.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}4.jpeg`} alt="Kuća K Zabok" />
          <img src={`${URL}5.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}6.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="oneR">
          <img src={`${URL}7.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}8.jpeg`}alt="Kuća K Zabok" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}9.jpeg`} alt="Kuća K Zabok" />
          <img src={`${URL}10.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="oneL">
          <img src={`${URL}A1.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}A2.jpeg`}alt="Kuća K Zabok" />
          <img src={`${URL}A3.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}A4.jpeg`} alt="Kuća K Zabok" />
        </div>
        <div className="oneR">
          <img src={`${URL}A5.jpeg`} alt="Kuća K Zabok" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project1;
