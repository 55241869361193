import { Link } from 'react-router-dom';
import './style.scss';
import logo from '../../assets/kostelgrad_transparent.webp';

const Footer = () => {
  return (
    <footer>
      <section className="logo">
        <img src={logo} alt="Kostelgrad logo" />
      </section>
      <section>
        <ul>
          <h3>
            <Link to="projekti">Projekti</Link>
          </h3>
          <li>
            <Link to="projekti">Stambene zgrade</Link>
          </li>
          <li>
            <Link to="projekti">Poslovne zgrade</Link>
          </li>
          <li>
            <Link to="projekti">Krajobranza arhitektura</Link>
          </li>
          <li>
            <Link to="projekti">Interijeri</Link>
          </li>
        </ul>
        <ul>
          <h3>Kostelgrad projekt</h3>
          <li>
            <Link to="o-nama">O nama</Link>
          </li>
          <li>
            <Link to="o-nama">Karijere</Link>
          </li>
          <li>
            <Link to="kontakt">Kontakt</Link>
          </li>
        </ul>
        {/* <ul>
          <h3>Pratite nas</h3>
          <li>
            <Link to="/">Facebook</Link>
          </li>
          <li>
            <Link to="/">Instagram</Link>
          </li>
          <li>
            <Link to="/">LinkedIn</Link>
          </li>
        </ul> */}
        <ul>
          <h3>Zakonski okviri</h3>

          <li>
            <Link to="politika-privatnosti">Politika privatnosti</Link>
          </li>
        </ul>
      </section>
      <section>
        <p>Kostelgrad-projekt 2023. &copy; Sva prava pridržana.</p>
        <p>
          Developed by <Link to="//www.livens.co">Livens</Link>
        </p>
      </section>
    </footer>
  );
};

export default Footer;
