import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Vrt-E/';

const Project14 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>14</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Vrt E</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Krajobrazna arhitektura</p>
            <p>Lokacija: Hrvatska</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
      <div className="fullWidthImage">
          <img src={`${URL}1.jpeg`} alt="Vrt E" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}2.jpeg`} alt="Vrt E" />
          <img src={`${URL}3.jpeg`} alt="Vrt E" />
        </div>
        <div className="oneL">
          <img src={`${URL}4.jpeg`} alt="Vrt E" />
        </div>
        <div className="twolLrS">
          <img src={`${URL}5.jpeg`} alt="Vrt E" />
          <img src={`${URL}6.jpeg`} alt="Vrt E" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}7.jpeg`} alt="Vrt E" />
        </div>
        <div className="oneR">
          <img src={`${URL}8.jpeg`} alt="Vrt E" />
        </div>
        <div className="fullWidthImage">
          <img src={`${URL}9.jpeg`} alt="Vrt E" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}10.jpeg`} alt="Vrt E" />
          <img src={`${URL}11.jpeg`} alt="Vrt E" />
        </div>
        <div className="oneL">
          <img src={`${URL}12.jpeg`} alt="Vrt E" />
        </div>
        
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project14;
