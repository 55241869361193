import { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { motion, AnimatePresence } from "framer-motion";

import project1 from "../../assets/carousel/vila-solta.jpeg";
import project2 from "../../assets/carousel/kuca-cc-pregrada.jpeg";
import project3 from "../../assets/carousel/djecji-vrtic-pregrada.jpeg";
import project4 from "../../assets/carousel/kuca-m-tuhelj.jpeg";
import project5 from "../../assets/carousel/zgrada-as-pregrada.jpeg";

const projects = [
  {
    id: 1,
    img: project1,
    slug: "vila-solta",
    title: "Vila Šolta",
  },
  {
    id: 2,
    img: project2,
    slug: "kuca-cc-pregrada",
    title: "Kuća Cc Pregrada",
  },
  {
    id: 3,
    img: project3,
    slug: "djecji-vrtic-pregrada-vrhi",
    title: "Dječji vrtić Pregrada Vrhi",
  },
  {
    id: 4,
    img: project4,
    slug: "kuca-m-tuhelj",
    title: "Kuća M Tuhelj",
  },
  {
    id: 5,
    img: project5,
    slug: "zagrada-as-pregrada",
    title: "Zgrada AS Pregrada",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBackClick = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? projects.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    const isLastSlide = currentIndex === projects.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    let timer;

    timer = setInterval(() => {
      const isLastSlide = currentIndex === projects.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }, 8000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex]);

  return (
    <div className="carousel">
      <AnimatePresence mode="sync">
        <motion.div
          key={currentIndex ? currentIndex.label : "empty"}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0 }}
        >
          <div className="projectImage">
            <img src={`${projects[currentIndex].img}`} alt="" />
            <div className="imageOverlay"></div>
          </div>
          <div className="projectInfo">
            <h1>{`${projects[currentIndex].title}`}</h1>
            <Link to={`/projekti/${projects[currentIndex].slug}`}>
              Pogledaj projekt
            </Link>
            <div className="navigation">
              <button onClick={handleBackClick}>
                <KeyboardArrowLeftIcon />
              </button>
              <button onClick={handleNextClick}>
                <KeyboardArrowRightIcon />
              </button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Carousel;
