import { useState } from 'react';
import './style.scss';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import user from '../../assets/user.webp';

const AboutUs = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="aboutPage">
        <div className="aboutText">
          <p>
            Pružamo sveobuhvatne projektantske i konzalting usluge, od idejnog
            rješenja do nadzora nad izgradnjom građevina.
          </p>

          <div
            className="aboutButton"
            onClick={() => {
              setOpenModal(!openModal);
            }}
          >
            <FiberManualRecordIcon /> Više o nama
          </div>
        </div>

        <div className="aboutServices" style={{ backgroundColor: 'white' }}>
          <div className="title">
            <h3>O nama</h3>
          </div>
          <div className="services">
            <div className="serviceContainer">
              <h4>500+</h4>
              <p>Uspješnih projekata</p>
            </div>

            <div className="serviceContainer">
              <h4>30+</h4>
              <p>Godina iskustva</p>
            </div>
          </div>
        </div>
        <div className="aboutUs">
          <p>
            Projektni ured “Kostelgrad-projekt” d.o.o. osnovao je 1993. godine
            arhitekt Josip Golubić s namjerom da se približi potencijalnim
            investitorima na području ovog dijela Hrvatskog zagorja. Od početnog
            jednog zaposlenika narasli smo na tim od osam stručnih osoba koje
            pružaju brojne stručne projektantske i konzalting usluge.
            Projektiramo samostalno ili u suradnji sa stručnim suradnicima. Cilj
            nam je pružiti kompletnu uslugu zainteresiranima od idejnog
            rješenja, izrade glavnog projekta, ishođenja lokacijskih i građevnih
            dozvola do nadzora nad izgradnjom građevina i ishođenja uporabne
            dozvole.
          </p>
        </div>
        <div className="aboutServices">
          <div className="title">
            <h3>Naše usluge</h3>
          </div>
          <div className="services">
            <div className="serviceContainer">
              <h3>Projektiranje</h3>
              <p>
                Izrada idejnih rješenja, idejnog projekta, glavnog projekta,
                izvedbenog projekta, ishođenje dozvola za građenje.
              </p>
            </div>
            <div className="serviceContainer">
              <h3>Izrada troškovnika i procijena vrijednosti nekretnine</h3>
              <p>
                Izrađujemo troškovnike za ishođenje kredita, procjene
                vrijednosti zgrada i nekretnina – ovlašteni sudski vještak.
              </p>
            </div>
            <div className="serviceContainer">
              <h3>Nadzor</h3>
              <p>Vršimo projektantski i stručni nadzor prilikom gradnje.</p>
            </div>
            <div className="serviceContainer">
              <h3>Konzalting</h3>
              <p>
                Savjetujemo investitore u svim fazama projektiranja i gradnje.
              </p>
            </div>
          </div>
        </div>

        <div className="teamHeader">
          <p>
            Mi smo tim stručnjaka iz područja arhitekture, građevinarstva i
            krajobrazne arhitekture.{' '}
          </p>
          <div className="joinUs">
            <FiberManualRecordIcon /> Pridruži se timu
          </div>
          <div className="joinUsText">
            <p>Zapošljavamo arhitekta/icu inženjera/ku građevine s pretenzijama prema niskogradnji i projektiranju vodovoda i odvodnje; iskustvo u struci je poželjno, ali nije obavezno; nudimo mentorski rad i napredovanje.</p>
          </div>
        </div>
        <div className="teamGrid">
          <div className="gridColumn" style={{ marginTop: '8rem' }}>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Stjepko Golubić</span> <FiberManualRecordIcon /> Direktor,
                krajobrazni arhitekt
              </p>
            </div>

            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Leona Zajec</span> <FiberManualRecordIcon /> Inženjerka
                građevinarstva, projektantica
              </p>
            </div>
          </div>
          <div className="gridColumn" style={{ marginTop: '0rem' }}>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Josip Golubić</span> <FiberManualRecordIcon /> Prokurist,
                arhitekt, nadzorni inženjer, sudski vještak
              </p>
            </div>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Vesna Vešligaj</span> <FiberManualRecordIcon />{' '}
                Građevinska tehničarka
              </p>
            </div>
          </div>
          <div className="gridColumn" style={{ marginTop: '8rem' }}>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Vedrana Gorup</span> <FiberManualRecordIcon /> Ovlaštena
                arhitektica, projektantica
              </p>
            </div>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Valerija Golubić</span> <FiberManualRecordIcon />{' '}
                Administratorica
              </p>
            </div>
          </div>
          <div className="gridColumn" style={{ marginTop: '0rem' }}>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Danko Kantoci</span> <FiberManualRecordIcon /> Arhitekt
              </p>
            </div>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Mario Očko</span> <FiberManualRecordIcon /> Inženjer
                građevinarstva, projektant, nadzorni inženjer
              </p>
            </div>
            <div className="gridItem">
              <img src={user} alt="" />
              <p>
                {' '}
                <span>Filip Blažun</span> <FiberManualRecordIcon /> Suradnik,
                student građevinarstva
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="aboutUsOverlay "
        style={{ display: openModal ? 'flex' : 'none' }}
      >
        <div className="aboutUsContainer">
          <h3>više o nama</h3>

          <p style={{ textIndent: '1.5em' }}>
            Naš arhitektonski ured pruža sveobuhvatne projektantske i konzalting
            usluge, pokrivajući čitav spektar procesa od idejnog rješenja do
            nadzora nad izgradnjom građevina. Sa preko 500 uspješno realiziranih
            projekata i preko 30 godina iskustva, naša stručnost i predanost su
            neupitni.
          </p>
          <p>
            Naša usluga uključuje izradu idejnih rješenja, idejnih projekata,
            glavnih projekata, izvedbenih projekata te ishođenje svih potrebnih
            dozvola za građenje. Također, izrađujemo troškovnike potrebne za
            ishođenje kredita te provodimo procjene vrijednosti zgrada i
            nekretnina kao ovlašteni sudski vještaci.
          </p>
          <p>
            Kao odgovorni projektanti, također pružamo projektantski i stručni
            nadzor tijekom samog procesa gradnje. Naš cilj je osigurati da svaki
            projekt bude izveden s najvišim standardima kvalitete i sigurnosti.
          </p>
          <p>
            Nudimo i stručne savjete investitorima u svim fazama projektiranja i
            gradnje. Naš tim se sastoji od stručnjaka iz područja arhitekture,
            građevinarstva i krajobrazne arhitekture, što nam omogućava da
            pružimo sveobuhvatnu podršku i rješenja za različite projekte.
          </p>
          <p>
            S našim iskustvom, stručnošću i strašću za arhitekturom i
            građevinskim projektima, spremni smo ispuniti sve vaše zahtjeve i
            surađivati na ostvarenju vaših ambicija u svijetu građevine i
            arhitekture.
          </p>
        </div>
        <div
          onClick={() => {
            setOpenModal(!openModal);
          }}
        ></div>
      </div>
    </>
  );
};

export default AboutUs;
