import './style.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const URL =
  'https://kostelgrad-projekt.s3.eu-central-1.amazonaws.com/projects/Kuce-u-nizu-G-Pregrada/';

const Project39 = () => {
  return (
    <div className="projectPage">
      <div className="projectPage__header">
        <div className="projectNumber">
          <span>39</span>
        </div>
        <div className="title">
          <span>Projekt</span>
          <span>Kuće u nizu G Pregrada</span>
          <h3>Scroll</h3>
        </div>
        <div className="projectDetails">
          <ul>
            <p>Stambena namjena</p>
            <p>Lokacija: Pregrada</p>
          </ul>
        </div>
      </div>
      <div className="projectPage__images">
        <div className="fullWidthImage">
          <img src={`${URL}nizu.jpeg`} alt="Kuće u nizu G Pregrada" />
        </div>
        <div className="twolSrL">
          <img src={`${URL}nizu2.jpeg`} alt="Kuće u nizu G Pregrada" />
          <img src={`${URL}nizu3.jpeg`} alt="Kuće u nizu G Pregrada" />
        </div>
      </div>
      <div className="projectPage__footer">
        <h3>Istraži više</h3>
        <Link to="/projekti">
          <h1>Svi</h1>
          <h1>Projekti</h1>
          <div className="arrow">
            <ArrowForwardIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project39;
